<template>
  <div class="order">
    <div class="export-btn-wrap" v-if="exportLength > 0">
      <el-button plain size="mini" @click="handleExport">导出</el-button>
    </div>
    <el-table :data="tableData" border v-loading="loading" size="mini" ref="toSendTable" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="order_id" label="订单号" width="140"></el-table-column>
      <el-table-column prop="title" label="样品名称"></el-table-column>
      <el-table-column prop="nickname" label="采购人" width="100"></el-table-column>
      <el-table-column prop="corporation" label="采购企业"></el-table-column>
      <el-table-column prop="create_time" label="下单时间" width="140"></el-table-column>
      <el-table-column prop="mark" label="备注"></el-table-column>
      <el-table-column prop="status" label="订单状态" width="70">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1" type="warning" size="mini">待发货</el-tag>
          <el-tag v-if="scope.row.status == 2" size="mini">已发货</el-tag>
          <el-tag v-if="scope.row.status == 3" type="success" size="mini">已收货</el-tag>
          <el-tag v-if="scope.row.status == 4" type="info" size="mini">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="handleExpress(scope.$index, scope.row.id)">发货</el-button>
          <el-button size="mini" @click="handleMark(scope.$index, scope.row.id, scope.row.mark)">备注</el-button>
          <el-button type="primary" plain size="mini" @click="handleDetail(scope.row.id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next, total" :page-size="15" @current-change="handle_page_current" :current-page.sync="page" :total="count"></el-pagination>
    <el-dialog title="添加备注" :visible.sync="markDialogVisible">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="mark" maxlength="50" show-word-limit></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="markDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="markOrder">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="detailDialogVisible">
      <el-row>
          <el-col :span="18">
            <el-row class="detai_item">
              <el-col :span="24">样品标题：{{detail.title}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">样品ID： {{detail.gid}}</el-col>
              <el-col :span="12">订单状态：
                <el-tag size="small" type="warning" v-if="detail.status == 1">待发货</el-tag>
                <el-tag size="small" v-if="detail.status == 2">已发货</el-tag>
                <el-tag size="small" type="success" v-if="detail.status == 3">已收货</el-tag>
                <el-tag size="small" type="info" v-if="detail.status == 4">已取消</el-tag>
              </el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">采购单位： {{detail.corporation}}</el-col>
              <el-col :span="12">采购人：{{detail.nickname}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">订单编号：{{detail.order_id}}</el-col>
              <el-col :span="12">联系电话：{{detail.tel}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">收货地址：{{detail.address}}</el-col>
              <el-col :span="12">收货人：{{detail.name}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">快递单号：{{detail.express_number}}</el-col>
              <el-col :span="12">快递公司：{{detail.express}}</el-col>
            </el-row>
            <el-row>
              <el-col>备注： {{detail.mark}}</el-col>
            </el-row>
          </el-col>
        <el-col :span="6">
          <el-timeline>
            <el-timeline-item :timestamp="detail.create_time">下单时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.send_time">发货时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.receipt_time">收货时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.comment_time">评价时间</el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="发货" :visible.sync="expressDialogVisible" width="30%">
      <el-form :model="express">
        <el-form-item label="快递公司">
          <el-input v-model="express.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input v-model="express.code" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="expressDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateExpress">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx'
var columns = [
  { label: '订单ID', value: 'order_id' },
  { label: '商品名称', value: 'title' },
  { label: '单位名称', value: 'cname' },
  { label: '收货人', value: 'aname' },
  { label: '联系电话', value: 'tel' },
  { label: '省', value: 'province' },
  { label: '市', value: 'city' },
  { label: '区', value: 'area' },
  { label: '详细地址', value: 'detail' },
  { label: '下单时间', value: 'create_time' }
]
var settings = {
  sheetName: 'FirstSheet',
  fileName: '待发货订单'
}
export default {
  name: 'Order',
  data () {
    return {
      page: 1,
      count: 0,
      loading: true,
      row: '', // 正在编辑(备注)的行
      id: '', // 要备注的订单ID
      mark: '', // 要备注的订单备注
      mark_bk: '', // 备份备注，用作提交前对比
      tableData: [],
      detail: {},
      markDialogVisible: false,
      detailDialogVisible: false,
      expressDialogVisible: false,
      express: {
        name: '',
        code: '',
        id: '',
        index: ''
      },
      exportOrder: []
    }
  },
  methods: {
    handleExport () {
      this.getExportXlsData(this.exportOrder)
      this.$refs.toSendTable.clearSelection()
    },
    handleSelectionChange (v) {
      var orderId = []
      v.forEach(element => {
        orderId.push(element.id)
      })
      this.exportOrder = orderId
    },
    handleExpress (index, id) {
      this.express.name = ''
      this.express.code = ''
      this.express.id = id
      this.express.index = index
      this.expressDialogVisible = true
    },
    handleMark (index, id, mark) {
      this.markDialogVisible = true
      this.id = id
      this.mark = mark
      this.row = index
      this.mark_bk = mark
    },
    handleDetail (id) {
      this.detailDialogVisible = true
      this.getDetail(id)
    },
    handle_page_current (e) {
      this.getData(e)
    },
    updateExpress () {
      var that = this
      this.axios.post('/admin/order/express', this.express)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.tableData.splice(that.express.index, 1)
            that.expressDialogVisible = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDetail (id) {
      var that = this
      that.detail = {}
      this.axios.get('/admin/order/one?id=' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.detail = response.data.detail
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getData (e) {
      var that = this
      that.loading = true
      var data = { status: 1 }
      this.axios.post('/admin/order?page=' + e, data)
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
            that.loading = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    markOrder () {
      var that = this
      if (that.mark === that.mark_bk) {
        that.markDialogVisible = false
      } else {
        this.axios.post('/admin/order/mark', { id: this.id, mark: this.mark })
          .then(function (response) {
            if (response.data.code === 0) {
              that.$message({
                message: response.data.msg,
                type: 'success'
              })
              that.tableData[that.row].mark = that.mark
              that.markDialogVisible = false
            } else {
              that.$message.error('操作失败！')
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
    getExportXlsData (id) {
      var that = this
      this.axios.post('/admin/order/exportxls', { id: id })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            var download = true
            xlsx(columns, response.data.list, settings, download)
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    exportLength: function () {
      return this.exportOrder.length
    }
  },
  mounted () {
    this.getData(this.page)
  }
}
</script>

<style scoped>
.detai_item{
  margin-bottom: 20px;
}
.export-btn-wrap{
  margin-bottom: 10px;
}
</style>
